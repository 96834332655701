<template>
  <div>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-row>
          <v-col>
            <v-text-field
              v-model="newMeeting.name"
              :rules="[v => !!v || 'Le nom est requis']"
              dense
              outlined
              label="Nom de la réunion"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="8"
          >
            <v-select
              v-model="newMeeting.users[0].user"
              outlined
              dense
              :rules="[v => !!v || 'Le tuteur est requis']"
              label="Tuteur"
              :items="tuteurs"
              item-text="user.displayName"
              item-value="user._id"
              no-data-text="Vous n'avez pas de tuteurs :("
            ></v-select>
          </v-col>
          <v-col
            cols="4"
          >
            <v-switch
              v-model="displayTutCalendar"
              :disabled="newMeeting.tuteurs === null"
              label="Calendrier du tuteur"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="newMeeting.note"
              outlined
              dense
              label="Note"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            md="6"
          >
            <v-menu
              ref="menuDateStart"
              v-model="menuDateStart"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateStartFormatted"
                  dense
                  outlined
                  label="Date de la réunion"
                  :prepend-icon="icons.mdiCalendar"
                  v-bind="attrs"
                  @blur="dateStart = parseDate(dateStartFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateStart"
                locale="fr"
                no-title
                @input="menuDateStart = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            md="3"
          >
            <v-autocomplete
              v-model="startTime"
              label="de"
              :items="intervals"
              auto-select-first
              dense
              outlined
              @change="changeEndtime"
            ></v-autocomplete>
          </v-col>
          <v-col md="3">
            <v-autocomplete
              v-model="endTime"
              label="à"
              outlined
              :items="intervalsFiltered"
              auto-select-first
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <p>Prochain rendez-vous prévu le <strong>{{ dateStartFull | date('dd MMMM yyyy') }}</strong> de <strong>{{ dateStartFull | date('HH:mm') }}</strong> à <strong>{{ dateEndFull | date('HH:mm') }}</strong> ({{ eventDuration/60 }} h)</p>
          </v-col>
        </v-row>

        <v-expand-transition>
          <div v-show="displayTutCalendar">
            <v-row>
              <v-col>
                <TuteurCalendar
                  :date="dateStart"
                  :selected-tuteur="newMeeting.tuteurs"
                  :new-meeting-name="newMeeting.name"
                  :new-meeting-start="dateStartFull"
                  :new-meeting-end="dateEndFull"
                  @dateUpdated="setDateCalendar"
                  @timeSet="setTimeCalendar"
                />
              </v-col>
            </v-row>
          </div>
        </v-expand-transition>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        color="primary"
        outlined
        @click="$emit('closeDialog')"
      >
        Annuler
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="createMeeting"
      >
        Créer
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import TuteurCalendar from '@/components/TuteurCalendar.vue'

export default {
  components: {
    TuteurCalendar,
  },
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
    meetings: {
      type: Array,
      default: () => [],
    },
    tuteur: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: true,
      menuDateStart: false,
      startTime: '10:15',
      endTime: '11:15',
      dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateStartFormatted: this.$date(new Date(), 'dd/MM/yyyy'),
      icons: {
        mdiCalendar,
      },
      newMeeting: {
        contacts: [
          {
            contact: this.contact._id,
          },
        ],
        type: 'tutorat',
        name: '',
        start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        end: new Date(new Date().getTime + 60 * 60 * 100),
        description: '',
        users: [{
          user: null,
        }],
      },
      displayTutCalendar: false,
    }
  },
  computed: {
    intervals() {
      const intervals = []
      const date = new Date()
      const format = {
        hour: 'numeric',
        minute: 'numeric',
      }

      for (let minutes = 0; minutes < 24 * 60; minutes += 15) {
        date.setHours(0)
        date.setMinutes(minutes)
        intervals.push(date.toLocaleTimeString('fr', format))
      }

      return intervals
    },

    tuteurs() {
      const tuteurs = this.$store.state.userlist.filter(x => x.status === 'tuteur')
      if (tuteurs) {
        return tuteurs
      }

      return []
    },
    dateStartFull() {
      if (!this.startTime) return null
      const [hours, minutes] = this.startTime.split(':')
      const date = new Date(this.dateStart)
      date.setHours(hours, minutes, 0)

      return date
    },
    dateEndFull() {
      if (!this.endTime) return null
      const [hours, minutes] = this.endTime.split(':')
      const date = new Date(this.dateStart)
      date.setHours(hours, minutes, 0)

      return date
    },
    eventDuration() {
      const diff = (this.dateEndFull.getTime() - this.dateStartFull.getTime())
      const diffmins = Math.abs((diff / 1000) / 60)

      return diffmins
    },
    intervalsFiltered() {
      let index = this.intervals.findIndex(x => x === this.startTime)
      index += 1

      return this.intervals.slice(index)
    },
  },
  watch: {
    dateStart(val) {
      this.dateStartFormatted = this.formatDate(val)
    },
    meetings() {
      this.generateEventName()
    },
    contact(val) {
      this.generateEventName()
      this.newMeeting.contacts = [{ contact: val._id }]
    },
    tuteur(val) {
      this.newMeeting.tuteurs = val._id
    },
  },
  mounted() {
    this.generateEventName()
    if (this.tuteur) {
      this.newMeeting.tuteurs = this.tuteur.id
    }
  },
  methods: {
    generateEventName() {
      if (this.contact.course) {
        if (this.meetings) {
          this.newMeeting.name = `${this.contact.firstName} ${this.contact.lastName} - ${this.contact.course.name} : Visio ${Number(this.meetings.length) + 1}`
        } else {
          this.newMeeting.name = `${this.contact.firstName} ${this.contact.lastName} - ${this.contact.course.name} : Premier visio`
        }
      } else if (this.meetings) {
        this.newMeeting.name = `${this.contact.firstName} ${this.contact.lastName} : Visio ${Number(this.meetings.length) + 1}`
      } else {
        this.newMeeting.name = `${this.contact.firstName} ${this.contact.lastName} : Premier visio`
      }
    },
    createMeeting() {
      if (this.$refs.form.validate()
      ) {
        this.$http.post(`${process.env.VUE_APP_API_URL}/event/new`, this.newMeeting)
          .then(res => {
            this.$emit('meetingAdded', res.data)
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Nouveau RDV de visio-conférence ajouté',
              value: true,
            })
            this.$emit('closeDialog')
            this.setupData()
          })
          .catch(err => {
            console.log(err)
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la création du RDV.',
              value: true,
            })
          })
      }
    },
    setupData() {
      this.startTime = '10:15'
      this.endTime = '11:15'
      this.dateStart = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.newMeeting = {
        name: '',
        start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        end: new Date(new Date().getTime + 60 * 60 * 100),
        description: '',
        users: [{
          user: null,
        }],
      }
      this.displayTutCalendar = false
      this.$emit('setupData')
    },

    changeEndtime(e) {
      const index = this.intervals.findIndex(x => x === e)

      this.endTime = this.intervals[index + 4]
    },
    formatDate(date) {
      if (!date) return null

      return this.$date(new Date(date), 'dd/MM/yyyy')
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    setTimeCalendar(payload) {
      this.startTime = payload
      this.changeEndtime(payload)
    },
    setDateCalendar(payload) {
      this.dateStart = payload.start.date
    },
  },
}
</script>

<style>

</style>
