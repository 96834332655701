<template>
  <v-dialog
    v-model="meetingDialog"
    persistent
    width="900"
    @click:outside="$emit('closeDialog')"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Visio-conférence
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedcontact"
              :disabled="loading"
              label="Choisir l'apprenant"
              :items="contacts"
              :item-text="item=>`${item.firstName} ${item.lastName}`"
              :item-value="item => item"
              @change="getMeetings"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <ContactMeetingTimeTable
        v-if="!meetingsData"
        :contact="hydratecontact"
        :meetings="hydrateMeetings"
      >
      </ContactMeetingTimeTable>
      <NewMeetingDialogForm
        v-if="!contactData"
        :meetings="hydrateMeetings"
        :contact="hydratecontact"
        :tuteur="hydrateTuteur"
        @closeDialog="$emit('closeDialog')"
        @setupData="setupData"
        @meetingAdded="meetingAdded"
      >
      </NewMeetingDialogForm>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactMeetingTimeTable from './ContactMeetingTimeTable.vue'
import NewMeetingDialogForm from './NewMeetingDialogForm.vue'

export default {
  components: {
    ContactMeetingTimeTable,
    NewMeetingDialogForm,
  },
  props: {
    meetingDialog: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: null,
    },
    meetings: {
      type: Array,
      default: null,
    },
    timeSet: {
      type: Object,
      default: null,
    },
    tuteur: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      contacts: [],
      selectedcontact: null,
      selectedMeetings: null,
    }
  },
  computed: {
    contactNotProvided() {
      return (this.contact == null)
    },
    meetingsNotProvided() {
      return (this.meetings == null)
    },
    tuteurNotProvided() {
      return (this.tuteur == null)
    },
    contactData() {
      return (this.contact == null && this.selectedcontact == null)
    },
    meetingsData() {
      return (this.meetings == null && this.selectedMeetings == null)
    },
    hydratecontact() {
      return this.contactNotProvided ? this.selectedcontact : this.contact
    },
    hydrateMeetings() {
      return this.meetingsNotProvided ? this.selectedMeetings : this.meetings
    },
    hydrateTuteur() {
      return this.tuteurNotProvided ? null : this.tuteur
    },

  },
  watch: {
    contact() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.contactNotProvided) {
        Promise.all([this.fetchcontacts()])
          .then(values => {
            [this.contacts] = values
          })
          .catch(err => console.log(err))
          .finally(this.loading = false)
      } else {
        Promise.all([this.fetchcontact()])
          .then(values => {
            [this.selectedcontact] = values
            this.contacts = values
          })
          .catch(err => console.log(err))
          .finally()
      }
    },
    async fetchcontacts() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/contact/all`)

      return res.data
    },
    async fetchcontact() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/contacts/${this.contact.id}`)

      return res.data
    },
    getMeetings() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/event/find?contacts.contact=${this.hydratecontact._id}`)
        .then(res => {
          this.selectedMeetings = res.data
        })
        .catch(err => console.log(err))
    },
    meetingAdded(payload) {
      this.$emit('meetingAdded', payload)
    },
    setupData() {
      this.selectedcontact = null
      this.selectedMeetings = null
    },
  },

}
</script>

<style>

</style>
