<template>
  <v-card-text>
    <v-simple-table
      v-if="meetings.length > 0"
      :height="givenHeight"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              Total heures de visio
            </th>
            <th class="text-center">
            </th>
            <th class="text-center">
              {{ durees.visio / 60 }} h
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(meeting, index) in orderedMeetings"
            :key="index"
            @click="editMeeting(meeting)"
          >
            <td
              class="text-center"
              :class="isPast(meeting.start) ? 'strike': ''"
            >
              <b>{{ meeting.name }}</b> <br>
              <!--  <i> avec {{ meeting.users[0].displayName }}</i> -->
            </td>
            <td class="text-center">
              {{ new Date(meeting.start) | date('dd MMMM yyyy hh:mm') }}
            </td>
            <td class="text-center">
              - {{ meeting.duration / 60 }} h
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>Restant</strong>
            </td>
            <td></td>
            <td class="text-center">
              <strong>{{ (durees.visio - durees.meetings) /60 }} h</strong>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p
      v-else
      class="text-center"
    >
      Aucun visio pour l'instant
    </p>
  </v-card-text>
</template>

<script>

export default {
  props: {
    givenHeight: {
      type: Number,
      default: 250,
    },
    meetings: {
      type: Array,
      default: () => [],
    },
    contact: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fetchedFormation: {},
    }
  },
  computed: {
    durees() {
      let formationToUse = {}
      if (typeof this.student.formation === 'number') {
        formationToUse = this.fetchedFormation
      } else {
        formationToUse = this.student.formation
      }
      const durees = {}
      durees.formation = Number(formationToUse.durees) * 60
      durees.visio = Number(formationToUse.visio) * 60
      durees.meetings = 0
      this.meetings.forEach(meeting => {
        durees.meetings += Number(meeting.duration)
      })

      return durees
    },
    orderedMeetings() {
      const meetings = [...this.meetings]

      return meetings.sort((a, b) => new Date(a.end) - new Date(b.end))
    },
  },
  mounted() {
    if (typeof this.contact.course === 'string') {
      this.fetchFormation()
    }
  },
  methods: {
    isPast(date) {
      const dateNow = new Date()

      return new Date(date) < dateNow
    },
    editMeeting(e) {
      this.$emit('selectMeetingEdit', e)
    },
    fetchFormation() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/formations/${this.student.formation}`)
        .then(res => {
          this.fetchedFormation = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
  },

}
</script>

<style>
 .strike {
  text-decoration: line-through;
 }
</style>
