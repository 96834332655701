<template>
  <div>
    <div
      class="d-flex justify-space-between"
    >
      <v-btn
        icon
        color="primary"
        @click="$refs.calendar.prev()"
      >
        <v-icon small>
          {{ icons.mdiChevronLeft }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        color="primary"
        @click="$refs.calendar.next()"
      >
        <v-icon small>
          {{ icons.mdiChevronRight }}
        </v-icon>
      </v-btn>
    </div>
    <v-calendar
      ref="calendar"
      v-model="cal"
      color="primary"
      type="day"
      first-time="08:00"
      interval-count="15"
      interval-width="90"
      mode="stack"
      :events="orderedMeetings"
      locale="fr"
      @change="updateDate"
      @click:time="setTime"
    ></v-calendar>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
  props: {
    date: {
      type: String,
      default: new Date().toISOString(),
    },
    selectedTuteur: {
      type: Number,
      default: 0,
    },
    newMeetingName: {
      type: String,
      default: '',
    },
    newMeetingStart: {
      type: Date,
      default: () => new Date(),
    },
    newMeetingEnd: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      cal: '',
      meetings: [],
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
  computed: {
    orderedMeetings() {
      const meetings = []
      this.meetings.forEach(meet => {
        meetings.push({
          name: meet.nom,
          color: meet.type === 'timeoff' ? 'secondary' : 'primary',
          start: new Date(meet.start),
          end: new Date(meet.end),
          timed: true,
        })
      })

      meetings.push({
        name: this.newMeetingName,
        color: 'success',
        start: new Date(this.newMeetingStart),
        end: new Date(this.newMeetingEnd),
        timed: true,
      })

      return meetings
    },
  },
  watch: {
    date(val) {
      this.fetchData()
      this.cal = val
    },
    selectedTuteur() {
      this.fetchData()
    },

  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const date = this.$date(new Date(this.date), 'yyyy-MM-dd')
      const startDate = `${date}T00:00:00`
      const endDate = `${date}T23:59:59`
      this.$http.get(`${process.env.VUE_APP_API_URL}/meetings?users=${this.selectedTuteur}&start_gte=${startDate}&start_lte=${endDate}`)
        .then(res => {
          this.meetings = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
    setTime(e) {
      let minutes = 0
      let hours = e.hour
      if (e.minute > 7) {
        minutes = (Math.ceil(e.minute / 15) * 15)
      }
      if (e.minute >= 48) {
        minutes = 0
        hours += 1
      }

      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      if (hours < 10) {
        hours = `0${hours}`
      }

      const time = `${hours}:${minutes}`
      this.$emit('timeSet', time)
    },
    updateDate(e) {
      this.$emit('dateUpdated', e)
    },
  },
}
</script>

<style>

</style>
